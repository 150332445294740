import { createContext, FC, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { chain, noop, orderBy } from 'lodash';

import { OrganizationRes } from '../../sharedInterfaces/res';
import { get } from '../../utils/backend';
import { safeParseJson } from '../../utils/helpers';
import { UserContext } from '../user';

import {
  CompetencyRules360Res,
  LogoRes,
  OrganizationUI,
  OrgDimensionRes,
  OrgDimensionsRes,
  UserRoleRes,
} from './shared';

interface CommonContextType {
  annualRevenues: OrgDimensionRes[];
  commonLoading: boolean;
  companyAges: OrgDimensionRes[];
  industries: OrgDimensionRes[];
  logo: Partial<LogoRes>;
  organizations: OrganizationUI[];
  portfolioAccess: OrgDimensionRes[];
  portfolios: OrgDimensionRes[];
  roles: UserRoleRes[];
  sectors: OrgDimensionRes[];
  setReturnUrl: (string: string) => void;

  locationCountries: OrgDimensionRes[];
  headquartersCountries: OrgDimensionRes[];
  headquartersStates: OrgDimensionRes[];
  numberOfEmployees: OrgDimensionRes[];
  organization: Partial<OrganizationUI>;
  returnUrl: string | undefined;
  rules360: Partial<CompetencyRules360Res>;
  setLogo: (logo: Partial<LogoRes>) => void;
  refreshContext: () => void;
}

export const CommonContext = createContext<CommonContextType>({
  annualRevenues: [],
  commonLoading: true,
  companyAges: [],
  headquartersCountries: [],
  headquartersStates: [],
  industries: [],
  locationCountries: [],
  logo: {},
  numberOfEmployees: [],
  organization: {},
  organizations: [],
  portfolioAccess: [],
  portfolios: [],
  refreshContext: noop,
  returnUrl: undefined,
  roles: [],
  rules360: {},
  sectors: [],
  setLogo: noop,
  setReturnUrl: noop,
});

interface CommonProviderProps {
  children: ReactNode;
}

export const CommonProvider: FC<CommonProviderProps> = ({ children }) => {
  const { user } = useContext(UserContext);
  const [logo, setLogo] = useState<Partial<LogoRes>>({}); // TODO: should be LogoRes | null
  const [organizations, setOrganizations] = useState<OrganizationUI[]>([]);
  const [industries, setIndustries] = useState<OrgDimensionRes[]>([]);
  const [sectors, setSectors] = useState<OrgDimensionRes[]>([]);
  const [portfolios, setPortfolios] = useState<OrgDimensionRes[]>([]);
  const [portfolioAccess, setPortfolioAccess] = useState<OrgDimensionRes[]>([]);
  const [companyAges, setCompanyAges] = useState<OrgDimensionRes[]>([]);
  const [annualRevenues, setAnnualRevenues] = useState<OrgDimensionRes[]>([]);
  const [numberOfEmployees, setNumberOfEmployees] = useState<OrgDimensionRes[]>([]);
  const [headquartersStates, setHeadquartersStates] = useState<OrgDimensionRes[]>([]);
  const [headquartersCountries, setHeadquartersCountries] = useState<OrgDimensionRes[]>([]);
  const [locationCountries, setLocationCountries] = useState<OrgDimensionRes[]>([]);
  const [organization, setOrganization] = useState<Partial<OrganizationUI>>({});
  const [returnUrl, setReturnUrl] = useState<string>();
  const [roles, setRoles] = useState<UserRoleRes[]>([]);
  const [commonLoading, setCommonLoading] = useState<boolean>(true);
  const [rules360, setRules360] = useState<Partial<CompetencyRules360Res>>({}); // TODO: should be CompetencyRules360Res | null
  const [isMounted, setIsMounted] = useState<boolean>(false);

  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    };
  }, []);

  const refreshContext = () => {
    if (!isMounted) return;
    setCommonLoading(true);
    Promise.all([
      get('/organizations/brand').then((data = {}) => {
        if (!isMounted) return;
        if (data.img) {
          setLogo(data);
        } else {
          setLogo({});
        }
      }),
      get('/organizations').then((data: OrganizationRes[]) => {
        if (!isMounted) return;
        if (Array.isArray(data)) {
          const dataUI: OrganizationUI[] = chain(data)
            .map((item: OrganizationRes) => ({
              ...item,
              industries: safeParseJson<number[]>(item.industries, []),
              portfolios: safeParseJson<number[]>(item.portfolios, []),
              sectors: safeParseJson<number[]>(item.sectors, []),
            }))
            .orderBy((x) => x.orgname, 'asc')
            .value();
          setOrganizations(dataUI);
          setOrganization(dataUI.find((item) => item.id === user.orgId) ?? {});
        }
      }),
      get('/roles').then((data: UserRoleRes[]) => {
        if (!isMounted) return;
        if (Array.isArray(data)) {
          setRoles(data);
        }
      }),
      get('/organizations/dimensions').then((res: OrgDimensionsRes) => {
        if (!isMounted) return;
        setIndustries(orderBy(res.industries, (x) => x.name, 'asc'));
        setSectors(orderBy(res.sectors, (x) => x.name, 'asc'));
        setPortfolios(orderBy(res.portfoious, (x) => x.name, 'asc'));
        setPortfolioAccess(res.portfolioAccess);
        setCompanyAges(res.companyAges);
        setAnnualRevenues(res.annualRevenues);
        setNumberOfEmployees(res.numberOfEmployees);
        setHeadquartersStates(res.headquartersStates);
        setHeadquartersCountries(res.headquartersCountries);
        setLocationCountries(res.locationCountries);
      }),
      get('/organizations/competency_rules').then((data: CompetencyRules360Res) => {
        if (!isMounted) return;
        setRules360(data);
      }),
    ]).then(() => {
      if (!isMounted) return;
      setCommonLoading(false);
    });
  };

  useEffect(() => {
    if (!isMounted) return;
    if (!user.userId) return;
    refreshContext();
  }, [user.userId]);

  const value = useMemo(
    () => ({
      annualRevenues,
      commonLoading,
      companyAges,
      headquartersCountries,
      headquartersStates,
      industries,
      locationCountries,
      logo,
      numberOfEmployees,
      organization,
      organizations,
      portfolioAccess,
      portfolios,
      refreshContext,
      returnUrl,
      roles,
      rules360,
      sectors,
      setLogo,
      setReturnUrl,
    }),
    [
      annualRevenues,
      commonLoading,
      companyAges,
      headquartersCountries,
      headquartersStates,
      industries,
      locationCountries,
      logo,
      numberOfEmployees,
      organization,
      organizations,
      portfolioAccess,
      portfolios,
      refreshContext,
      returnUrl,
      roles,
      rules360,
      sectors,
      setLogo,
      setReturnUrl,
    ],
  );

  return <CommonContext.Provider value={value}>{children}</CommonContext.Provider>;
};
