/* eslint-disable max-lines */
import { ReactElement, ReactNode } from 'react';
import ReactDOMServer from 'react-dom/server';
import { RuleObject } from 'antd/es/form';
import _, { findIndex, isNil, isUndefined, mergeWith, omitBy, unionBy } from 'lodash';
import moment, { isMoment, Moment } from 'moment';

import { THEME_URL } from '../config';
import { DEFAULT_API_FORMAT, DEFAULT_UI_DATE_FORMAT } from '../constants';

import { FIELD_IS_REQUIRED, validateMessages } from './validation';

export function cloneDeep<T>(variable: T): T {
  return _.cloneDeepWith(variable, (value) => {
    if (isMoment(value)) {
      return value.clone();
    }
  });
}

export function arrayMove<T>(input: T[], from: number, to: number): T[] {
  const array = [...input];
  const [element] = array.splice(from, 1);
  array.splice(to, 0, element);
  return array;
}

export function formatDateOrRelativeWithinWeek(date: Date | Moment): string {
  const today = moment().startOf('day');
  const d = moment(date).startOf('day');
  const difference = d.diff(today, 'days');

  return difference === 0
    ? 'today'
    : Math.abs(difference) <= 7
    ? today.to(d)
    : d.format(DEFAULT_UI_DATE_FORMAT);
}

export function formatDate(
  dateInput: Moment | Date | string | number | undefined,
  frequency: 'monthly' | 'monthly_short' | 'month_day_short' | 'american' | undefined,
): string | undefined {
  if (!dateInput || !frequency) {
    return undefined;
  }
  const date = moment(dateInput, [DEFAULT_API_FORMAT, DEFAULT_UI_DATE_FORMAT]);
  if (!date.isValid()) {
    return undefined;
  }
  if (frequency === 'monthly') {
    return moment(date.startOf('month')).format('MMMM YYYY');
  }
  if (frequency === 'monthly_short') {
    return date.format('MMM YY');
  }
  if (frequency === 'month_day_short') {
    return date.format('MMM-DD');
  }
  if (frequency === 'american') {
    return date.format(DEFAULT_UI_DATE_FORMAT);
  }
  return date.format(DEFAULT_UI_DATE_FORMAT);
}

function isObject(value: any): boolean {
  return typeof value === 'object' && value !== null && !(value instanceof Array);
}

/**
 * @deprecated Use "obj?." instead"
 */
export function toObject(val: any): any {
  return isObject(val) ? val : {};
}

/**
 * @deprecated Use "arr?." or "(arr ?? [])" or "Object.values()" instead
 */
export function toArray<T>(value: any): T[] {
  if (value && isObject(value)) {
    return Object.keys(value).map((key) => value[key]);
  }
  return Array.isArray(value) ? value : [];
}

export function toFloat(val: any): number {
  if (Number.isNaN(val)) {
    return 0;
  }
  const type = typeof val;
  switch (true) {
    case type === 'number':
      return val;
    case type === 'boolean':
      return val ? 1 : 0;
    case type === 'string':
      return parseFloat(val) || 0;
    default:
      return 0;
  }
}

export function toInt(val: string | number): number {
  return Math.round(toFloat(val));
}

export function toBoolean(value: any): boolean {
  if (value === null || value === undefined) {
    return false;
  }
  if (typeof value === 'boolean') {
    return value;
  }
  if (typeof value === 'object') {
    return true;
  }
  if (typeof value === 'string' && ['yes', 'true', '1'].indexOf(value.toLowerCase()) >= 0) {
    return true;
  }
  value = toFloat(value);
  return value !== 0;
}

export function toString(val: any): string {
  const type = typeof val;
  switch (true) {
    case type === 'number':
      return val.toString();
    case type === 'boolean':
      return val ? '1' : '0';
    case type === 'string':
      return val;
    default:
      return '';
  }
}

export function deepEquals(
  a: any,
  b: any,
  strict = true,
  params: {
    emptyStringToUndefined?: boolean;
    falseToUndefined?: boolean;
    nullToUndefined?: boolean;
  } = {},
): boolean {
  function emptyStringToUndefined(x: string): string | undefined {
    return x === '' ? undefined : x;
  }

  function falseToUndefined(x: boolean): true | undefined {
    return x === false ? undefined : x;
  }

  function nullToUndefined(x: any): any | undefined {
    return x === null ? undefined : x;
  }

  if (typeof a === 'object' && a !== null) {
    if (typeof b !== 'object') {
      return false;
    }
    if (Array.isArray(a)) {
      if (!Array.isArray(b)) {
        return false;
      }
    }

    if (
      (moment.isMoment(a) && !moment.isMoment(b)) ||
      (moment.isMoment(b) && !moment.isMoment(a))
    ) {
      return false;
    }
    if (moment.isMoment(a) && moment.isMoment(b)) {
      return a.valueOf() === b.valueOf();
    }

    if ((strict || Array.isArray(a)) && Object.keys(a).length !== Object.keys(b).length) {
      return false;
    }
    const keysA = Object.keys(a);
    for (let i = 0; i < keysA.length; i++) {
      const key = keysA[i];
      // eslint-disable-next-line no-prototype-builtins
      if (strict && !b.hasOwnProperty(key)) {
        return false;
      }
      if (!deepEquals(a[key], b[key], strict, params)) {
        return false;
      }
    }
    const keysB = Object.keys(b);
    for (let i = 0; i < keysB.length; i++) {
      const key = keysB[i];
      // eslint-disable-next-line no-prototype-builtins
      if (strict && !b.hasOwnProperty(key)) {
        return false;
      }
      if (!deepEquals(a[key], b[key], strict, params)) {
        return false;
      }
    }
    return true;
  }
  if (params.emptyStringToUndefined) {
    a = emptyStringToUndefined(a);
    b = emptyStringToUndefined(b);
  }
  if (params.falseToUndefined) {
    a = falseToUndefined(a);
    b = falseToUndefined(b);
  }
  if (params.nullToUndefined) {
    a = nullToUndefined(a);
    b = nullToUndefined(b);
  }
  return a === b;
}

export function showTableTotal(total: number, range: [number, number]): string {
  return `${range[0]}-${range[1]} of ${total} items`;
}

export function getMessage(text: ReactNode): ReactElement {
  return (
    <span>
      <b>Don&apos;t close your page</b>
      <div>{text}</div>
    </span>
  );
}

export function mergeDeep(object: any, ...sources: any[]): any {
  return mergeWith(object, ...sources, (objValue: any, srcValue: any) => {
    if (Array.isArray(srcValue)) {
      return srcValue;
    }
    if (moment.isMoment(srcValue)) {
      return srcValue;
    }
  });
}

/**
 * @deprecated Use !isNil instead
 */
export function isSet(value: any): boolean {
  return !isNil(value);
}

export function capitalize(x: string | null | undefined): string {
  if (!x) {
    return '';
  }
  return x.charAt(0).toUpperCase() + x.slice(1);
}

export function capitalizeEachWord(input: string | null | undefined): string {
  if (!input) return '';
  return input
    .trim()
    .split(/\s+/) // Split by any whitespace (spaces, tabs, etc.)
    .map(capitalize)
    .join(' ');
}

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/**
 * returns not valid emails
 */
export function validateEmails(emails: string[]): string[] {
  return emails
    .filter((email) => String(email).trim())
    .filter((email) => !emailRegex.test(String(email)));
}

export const emailValidationRule = {
  validator: (rule: RuleObject, value: string): Promise<void> => {
    if (!value) {
      return Promise.reject(new Error(FIELD_IS_REQUIRED));
    }

    if (validateEmails([value]).length) {
      return Promise.reject(new Error(validateMessages.types.email));
    }

    return Promise.resolve();
  },
};

export function toMoney(value: number): string {
  return toString(value).length > 0 && toString(value) !== '-'
    ? `$${toString(Math.round(value * 100) / 100).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
    : toString(value);
}

export function toNumber(value: any): string {
  return toString(value).length > 0 && toString(value) !== '-'
    ? toString(Math.round(toFloat(value) * 100) / 100)
    : toString(value);
}

export function toPercent(value: number): string {
  if (toString(value).length < 1 || toString(value) === '-') {
    return toString(value);
  }

  if (value.toFixed) {
    return `${value.toFixed(2)}%`;
  }
  return `${toString(Math.round(value * 100) / 100)}%`;
}

export function importThemeModule(moduleName = ''): Promise<any> {
  if (!moduleName) {
    return Promise.reject();
  }
  const moduleUrl = `${THEME_URL}/${moduleName}?${Date.now()}`;
  return import(/* webpackIgnore: true */ moduleUrl);
}

// TODO: Remove
export function cleanDataFromUndefined(objects: any): any {
  return Object.fromEntries(
    Object.entries(objects).map(([key, value]) => [key, omitBy(value as any, isUndefined)]),
  );
}

/**
 * examples:
 * addOrReplace([{x: 1, y: 'a'}], {x: 1, y: 'b'}, 'x') => [{x: 1, y: 'b'}]
 * addOrReplace([{x: 1, y: 'a'}], {x: 2, y: 'b'}, 'x') => [{x: 1, y: 'a'}, {x: 2, y: 'b'}]
 */
export function addOrReplace<T>(array: T[], newItem: T, key: keyof T): T[] {
  const index = findIndex(array, [key, newItem[key]]);
  if (index > -1) {
    return unionBy(array, [newItem], key);
  }
  return [...array, newItem];
}

/**
 * Converts react component to string
 * -- NOTE --
 * You can spot overrides for console.error; it is needed to avoid warnings in the console.
 * Some of the components have handlers that don't work when rendering to a string.
 */
export const renderComponentToString = (node: ReactElement): string => {
  // eslint-disable-next-line no-console
  const originalConsoleError = console.error;
  // eslint-disable-next-line no-console
  console.error = (...args) => {
    if (/useLayoutEffect does nothing on the server/.test(args[0])) {
      return;
    }
    originalConsoleError(...args);
  };

  try {
    return ReactDOMServer.renderToString(node);
  } finally {
    // eslint-disable-next-line no-console
    console.error = originalConsoleError;
  }
};

export const safeParseJson = <T,>(json: string | null, defaultResult: T): T => {
  if (!json) return defaultResult;
  try {
    return JSON.parse(json);
  } catch (e) {
    return defaultResult;
  }
};

export function getDimensionValueTooltipText(
  dimensionName: string,
  dimensionValue: string,
): string | null {
  switch (dimensionValue) {
    case `No ${dimensionName} listed`:
      return `To assign ${dimensionName} to employees, update the participants file in the survey setup section.`;
    case 'Didn’t answer eNPS':
      return 'Some participants didn’t answer the eNPS question. To fix this, remind them to complete it if survey is still open.';
    case 'Not in org chart':
      return "These participants are missing from the organizational chart because there’s a break in their manager chain. Fix this by assigning missing managers in the participants' file in Entromy’s survey setup.";
    default:
      return null;
  }
}

export function getDimensionValueName(dimensionName: string, dimensionValue: string): string {
  if (dimensionValue?.toLowerCase() === 'uncategorized') {
    switch (dimensionName?.toLowerCase()) {
      case 'enps':
        return 'Didn’t answer eNPS';
      case 'level':
        return 'Not in org chart';
      default:
        return `No ${dimensionName} listed`;
    }
  }

  return dimensionValue;
}
