import { isNil } from 'lodash';

import { QUESTIONS_TYPES } from '../../../constants';
import { post } from '../../../utils/backend';
import { cloneDeep, getDimensionValueName, isSet, toArray } from '../../../utils/helpers';

import { getBaseUrl } from '.';

function mapDimension(current, compare, allQuestions, allCategories, surveyType, dimensionName) {
  const { categories, questions, dimensions } = current;

  function calcSpread(values) {
    const min = Math.min(...values);
    const max = Math.max(...values);
    if (surveyType === '360') {
      return values.length ? Math.round((max - min) * 10) / 10 : undefined;
    }
    return values.length ? Math.round(max - min) : undefined;
  }

  function calcScoreSpread(dimensionValues) {
    const values = Object.values(dimensionValues)
      .filter((item) => isSet(item.score))
      .map((item) => item.score);
    return calcSpread(values);
  }

  function calcParticipationRateSpread(dimensionValues) {
    const values = Object.values(dimensionValues)
      .filter((item) => isSet(item.participationRate))
      .map((item) => item.participationRate);
    return calcSpread(values);
  }

  function processDimensionValue(dimensionValue) {
    return getDimensionValueName(dimensionName, dimensionValue);
  }

  function setDimensionValues(dimensionValues, compareValues) {
    const values = {};
    dimensions.forEach((item) => {
      const dimension =
        dimensionValues.find((dim) => dim.name.toLowerCase() === item.name.toLowerCase()) || {};
      const compareDimension =
        toArray(compareValues).find((dim) => dim.name.toLowerCase() === item.name.toLowerCase()) ||
        {};
      let delta = null;
      if (isSet(compareDimension?.score) && isSet(dimension?.score)) {
        delta = dimension.score - compareDimension.score;
        if (surveyType === '360') {
          delta = Math.round(delta * 10) / 10;
        }
      }
      const valueName = processDimensionValue(item.name);
      values[valueName] = {
        score: dimension?.score,
        delta,
        respondents: dimension?.respondents,
      };
    });
    return values;
  }

  questions.forEach((question) => {
    question.keyTerm = allQuestions[question.id]?.keyTerm;
    question.categoryId = allQuestions[question.id]?.categoryId;
    question.typeId = allQuestions[question.id]?.typeId;
    if (
      question.typeId !== QUESTIONS_TYPES.FIXED &&
      !categories.find((item) => item.id === question.categoryId)
    ) {
      categories.push({
        id: question.categoryId,
        dimensionValues: [],
      });
    }
    let compareValues = null;
    if (compare) {
      const compareQuestion = toArray(compare.questions).find(
        (i) =>
          question.keyTerm === allQuestions[i.id]?.keyTerm &&
          question.categoryId === allQuestions[i.id]?.categoryId,
      );
      if (compareQuestion) {
        compareValues = compareQuestion.dimensionValues;
      }
    }
    if (question.typeId === QUESTIONS_TYPES.FIXED && surveyType === '360') {
      toArray(question.dimensionValues).forEach((item) => {
        if (item.score) {
          item.score = Math.round(item.score / 2) / 10;
        }
      });
      if (compareValues) {
        toArray(compareValues).forEach((item) => {
          if (item.score) {
            item.score = Math.round(item.score / 2) / 10;
          }
        });
      }
    }
    question.dimensionValues = setDimensionValues(question.dimensionValues, compareValues);
    question.spread = calcScoreSpread(question.dimensionValues);
  });

  categories.forEach((category) => {
    if (surveyType === '360') {
      toArray(category.dimensionValues).forEach((item) => {
        if (item.score) {
          item.score = Math.round(item.score / 2) / 10;
        }
      });
    }
    let compareValues = null;
    if (compare) {
      const compareCategory = compare.categories.find((c) => c.id === category.id);
      if (compareCategory) {
        compareValues = compareCategory.dimensionValues;
        if (surveyType === '360') {
          (compareCategory.dimensionValues ?? []).forEach((item) => {
            if (item.score) {
              item.score = Math.round(item.score / 2) / 10;
            }
          });
        }
      }
    }
    category.dimensionValues = setDimensionValues(category.dimensionValues, compareValues);
    category.spread = calcScoreSpread(category.dimensionValues);
    category.name = allCategories[category.id];
  });
  dimensions.forEach((item) => {
    if (surveyType === '360') {
      if (item.score) {
        item.score = Math.round(item.score / 2) / 10;
      }
    }
    const compareDimension = (compare?.dimensions ?? []).find((d) => d.name === item.name);
    if (surveyType === '360' && compareDimension?.score) {
      compareDimension.score = Math.round(compareDimension.score / 2) / 10;
    }

    const participationRate =
      item.participants > 0 ? Math.round((item.respondents / item.participants) * 100) : null;
    const compareParticipationRate =
      compareDimension?.participants > 0
        ? Math.round((compareDimension?.respondents / compareDimension.participants) * 100)
        : null;
    const participationRateDelta =
      isSet(participationRate) && isSet(compareParticipationRate)
        ? participationRate - compareParticipationRate
        : null;
    item.participationRate = participationRate;
    item.participationRateDelta = participationRateDelta;
    item.participantsDelta = item.respondents - compareDimension?.respondents;
  });
  if (compare) {
    dimensions.forEach((dim) => {
      const compareDimension = (compare?.dimensions ?? []).find(
        (d) => d.name.toLowerCase() === dim.name.toLowerCase(),
      );
      dim.delta = isSet(compareDimension?.score)
        ? Math.round((dim.score - compareDimension.score) * 10) / 10
        : undefined;
    });
  }

  dimensions.forEach((dim) => {
    dim.name = processDimensionValue(dim.name);
  });

  return {
    categories: categories.filter((c) => questions.filter((q) => q.categoryId === c.id).length),
    questions,
    dimensions,
    spread: calcScoreSpread(dimensions),
    participationRateSpread: calcParticipationRateSpread(dimensions),
  };
}

async function getFixedByDimension(
  filters,
  questions,
  categories,
  dimensions,
  settings,
  allFilters,
  slides,
  commonData,
) {
  const faPromices = [];
  const topinfluencersCode = 'topinfluencers';
  let topInfluencersCount = 0;
  const dimensionsData = [];
  const dimensionOrderList = toArray(settings?.ComparativeReview?.col?.list);
  let dimensionList = [];
  let defaultDimensionList = [];
  const ignoredDimensions = [
    'firstname',
    'lastname',
    'email',
    'alternative_email',
    'mobile',
    'employeeId',
  ];
  const importantDimensions = [
    commonData?.standardDimensions?.standardDimensionCodeToOrgDimensionCode?.department,
    commonData?.standardDimensions?.standardDimensionCodeToOrgDimensionCode?.manager,
    commonData?.standardDimensions?.standardDimensionCodeToOrgDimensionCode?.emprole,
    commonData?.standardDimensions?.standardDimensionCodeToOrgDimensionCode?.location,
    'tenure',
    'enps',
    topinfluencersCode,
  ].filter((x) => !isNil(x));
  [
    {
      id: -1,
      dimension_code: topinfluencersCode,
      display_name: 'Top Influencers',
    },
    ...dimensions.filter((d) => ignoredDimensions.indexOf(d.dimension_code.toLowerCase()) === -1),
  ]
    .filter((item) => allFilters.surveyType !== '360' || item.dimension_code === 'relationship')
    .forEach((d) => {
      const dim = dimensionOrderList.find((i) => i.id === d.id);
      if (dim) {
        dimensionList.push({
          id: dim.id,
          order: dimensionOrderList.findIndex((i) => i.id === d.id),
          dimension_code: dim.code || d.dimension_code,
          display_name: dim.name,
          status: dimensions.find((i) => dim.id === i.id)?.status,
          visible: dim.visible,
          visibleAppendix: dim.visibleAppendix,
          topInfluencersHidden: dim.topInfluencersHidden,
          consolidateCols: dim.consolidateCols,
          consolidateRows: dim.consolidateRows,
        });
      } else {
        dimensionList.push({
          ...d,
          visible: allFilters.surveyType === '360',
          visibleAppendix: allFilters.surveyType === '360',
          order: 1000,
        });
      }
    });
  dimensionList.sort((a, b) => a.order - b.order);

  defaultDimensionList = cloneDeep(dimensionList);
  if (allFilters.surveyType !== '360') {
    defaultDimensionList.forEach((item) => {
      const index = importantDimensions.indexOf(item.dimension_code);
      item.order = index === -1 ? 1000 : index;
      item.consolidateCols = true;
      item.consolidateRows = true;
      item.topInfluencersHidden = true;
      if (index !== -1) {
        item.visible = true;
        item.visibleAppendix = true;
        item.topInfluencersHidden = true;
      } else {
        item.visible = false;
        item.visibleAppendix = false;
      }
    });
  }
  defaultDimensionList.sort((a, b) => a.order - b.order);
  if (!dimensionOrderList.length) {
    dimensionList = cloneDeep(defaultDimensionList);
  }

  const departmentDimension = dimensionList.find(
    (i) =>
      i.id === commonData?.standardDimensions?.standardDimensionCodeToOrgDimensionId?.department,
  );
  if (allFilters.reportType === 'vcp' && departmentDimension) {
    departmentDimension.vcpDimension = true;
  }
  dimensionList
    .filter((d) => d.visible || d.vcpDimension)
    .forEach((dimension) => {
      const promise = new Promise((resolve, reject) => {
        const requestFilters = { ...filters, filter: dimension.dimension_code };
        const baseUrl = getBaseUrl(allFilters);
        let fixedUrl =
          dimension.dimension_code === topinfluencersCode
            ? `${baseUrl}/fixed_and_numerical_values_by_top_influencers`
            : `${baseUrl}/fixed_and_numerical_values_by_dimension`;
        if (
          allFilters.reportType === 'vcp' &&
          dimension.dimension_code === departmentDimension?.dimension_code
        ) {
          fixedUrl = '/reporting/vcp/fixed_and_numerical_values_by_department';
        }
        const subPromices = [
          post(fixedUrl, requestFilters).catch((error) => {
            reject(error);
          }),
        ];
        if (
          toArray(filters.compareSurveyIdList).length &&
          toArray(filters.compareHierarchyList).length
        ) {
          const compareFilters = {
            ...requestFilters,
            surveyIdList: filters.compareSurveyIdList,
            hierarchyList: filters.compareHierarchyList,
            groupIdList: filters.compareGroupIdList,
          };
          subPromices.push(
            post(fixedUrl, compareFilters).catch((error) => {
              reject(error);
            }),
          );
        }
        Promise.all(subPromices).then((rsp) => {
          if (rsp[0]?.dimensions?.length > (allFilters.surveyType === '360' ? 0 : 1)) {
            const sortBy = (array, field, method = 'asc') =>
              array.sort((a, b) => (method === 'asc' ? a[field] - b[field] : b[field] - a[field]));
            if (dimension.dimension_code === 'enps') {
              const list = ['Detractor', 'Passive', 'Promoter', 'Uncategorized'];
              rsp[0].dimensions.forEach((dim) => {
                dim.order = list.findIndex((i) => i === dim.name);
              });
              sortBy(rsp[0].dimensions, 'order', 'asc');
            } else {
              if (settings?.ComparativeReview?.col?.field === 'order') {
                const list = dimensionOrderList.find((i) => i.id === dimension.id)?.values;
                if (toArray(list).length) {
                  rsp[0].dimensions.forEach((dim) => {
                    dim.order = list.findIndex((i) => i === dim.name);
                  });
                }
              }
              sortBy(
                rsp[0].dimensions,
                settings?.ComparativeReview?.col?.field,
                settings?.ComparativeReview?.col?.method,
              );
            }
            if (
              toArray(rsp[0].questions).length &&
              toArray(rsp[0].dimensions).length > (allFilters.surveyType === '360' ? 0 : 1)
            ) {
              const dimensionData = {
                id: dimension.dimension_code,
                dimensionId: dimension.id,
                name: dimension.display_name,
                status: dimension.status,
                ...mapDimension(
                  rsp[0],
                  rsp[1],
                  questions,
                  categories,
                  allFilters.surveyType,
                  dimension.display_name,
                ),
              };
              dimensionsData.push(dimensionData);
            } else if (
              toArray(rsp[0].dimensions).length > (allFilters.surveyType === '360' ? 0 : 1)
            ) {
              const dimensionData = {
                id: dimension.dimension_code,
                dimensionId: dimension.id,
                name: dimension.display_name,
                status: dimension.status,
                dimensions: rsp[0].dimensions,
              };
              dimensionsData.push(dimensionData);
            }
            if (rsp[0]?.topInfluencersCount) {
              topInfluencersCount = rsp[0]?.topInfluencersCount;
            }
          }
          resolve();
        });
      });
      faPromices.push(promise);
    });
  await Promise.all(faPromices);

  dimensionsData.forEach((dim) => {
    const dimSettings = dimensionList.find((i) => i.dimension_code === dim.id);
    if (
      dimSettings.consolidateCols &&
      dim.dimensions.length >= 30 &&
      allFilters.reportType !== 'vcp'
    ) {
      const bucketDimensionValues = [];
      const dimValues = cloneDeep(toArray(dim.dimensions));
      dimValues.sort((a, b) => a.score - b.score);
      const bucketSize = Math.floor(dimValues.length / 4);
      const lastBucketSize = dimValues.length - 3 * bucketSize;
      [0, 1, 2].forEach((i) => {
        bucketDimensionValues[i] = dimValues
          .slice(i * bucketSize, i * bucketSize + bucketSize)
          .map((d) => d.name);
      });
      bucketDimensionValues[3] = dimValues.slice(-lastBucketSize).map((d) => d.name);
      dim.bucketDimensionValues = bucketDimensionValues;
    }
  });

  return {
    dimensionsData,
    topInfluencersCount,
    dimensionList,
    defaultDimensionList,
  };
}

export default getFixedByDimension;
