import { createContext, useEffect, useMemo, useState } from 'react';

import { FILTERS_STORAGE } from '../../constants';
import ss from '../../utils/ss';

export interface FiltersUI {
  allowRanking?: boolean;
  compareFilterGroupIdList?: number[];
  compareFilterHierarchyList?: string[];
  compareFilterIdList?: number[];
  compareFilterText?: string;
  compareGroupIdList?: number[];
  compareHierarchyList?: string[];
  compareSurveyIdList?: number[];
  compareSurveyName?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  compareSurveys?: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dimensionFilter?: any;
  dimensionFilterName?: string;
  filteredParticipants?: number;
  filteredParticipantsCompare?: number;
  filterGroupIdList?: number[];
  filterHierarchyList?: string[];
  filterIdList?: number[];
  filterText?: string;
  groupIdList?: number[];
  hierarchyList?: string[];
  isRunning?: boolean;
  loading: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  managers?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  managersCompare?: any;
  noAccess?: boolean;
  noData?: boolean;
  orgId?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  portfolioId?: any;
  questionIdList?: number[];
  surveyIdList?: number[];
  surveyName?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  surveys?: any[];
  surveyType?: string;
  totalParticipants?: number;
  totalParticipantsCompare?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  user360?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  user360Compare?: any;
}

interface FiltersContextProps {
  filters: FiltersUI;
  setFilters?: (filters: FiltersUI) => void;
  clearFilters?: () => void;
}

export const FiltersContext = createContext<FiltersContextProps>({
  filters: { loading: true },
});

interface FiltersProviderProps {
  children: React.ReactNode;
}

export const FiltersProvider = ({ children }: FiltersProviderProps) => {
  const [filters, setFilters] = useState({ loading: true });

  function clearFilters() {
    setFilters({ loading: true });
  }

  useEffect(() => {
    const savedFilters = ss.get<FiltersUI>(FILTERS_STORAGE);
    if (savedFilters) {
      setFilters({ ...savedFilters, loading: true });
    } else {
      setFilters({ loading: true });
    }
  }, []);

  useEffect(() => {
    ss.set(FILTERS_STORAGE, filters);
  }, [filters]);

  const value = useMemo(
    () => ({ clearFilters, filters, setFilters }),
    [filters, setFilters, clearFilters],
  );

  return <FiltersContext.Provider value={value}>{children}</FiltersContext.Provider>;
};
