import { post } from '../../../utils/backend';
import { getDimensionValueName, isSet, toArray } from '../../../utils/helpers';

import { getBaseUrl } from '.';

async function getNPSByDimension(filters, questions, allFilters, dimensionsData, dimensionList) {
  const npsBreakdownByDimension = [];
  const baseUrl = getBaseUrl(allFilters);
  let npsBreakdown = [];
  const breakdownPromices = [post(`${baseUrl}/nps_breakdown`, filters)];
  if (toArray(filters.compareSurveyIdList).length && toArray(filters.compareHierarchyList).length) {
    const compareFilters = {
      ...filters,
      surveyIdList: filters.compareSurveyIdList,
      hierarchyList: filters.compareHierarchyList,
      groupIdList: filters.compareGroupIdList,
    };
    breakdownPromices.push(post(`${baseUrl}/nps_breakdown`, compareFilters));
  }
  const data = await Promise.all(breakdownPromices);
  if (!toArray(data[0]).length) {
    return { npsBreakdown, npsBreakdownByDimension };
  }
  data[0].forEach((item) => {
    const question = questions[item.question_id];
    item.text = question.text;
    item.keyTerm = question.keyTerm;
  });
  npsBreakdown = data[0];
  if (toArray(data[1]).length) {
    npsBreakdown.forEach((q) => {
      const compQuestion = data[1].find((item) => item.question_id === q.question_id);
      if (compQuestion && isSet(compQuestion.value)) {
        q.delta = q.value - compQuestion.value;
      }
    });
  }
  const promices = [];

  const topinfluencersCode = 'topinfluencers';
  dimensionList
    .filter((dimension) => dimension.visible)
    .filter((item) => item.dimension_code !== 'enps')
    .forEach((dimension) => {
      const dimensionData = dimensionsData.find((d) => d.id === dimension.dimension_code);
      if (dimensionData) {
        const promise = new Promise((resolve) => {
          const dimensionValues = dimensionData.dimensions.map((i) => i.name).slice(0, 50);
          const url =
            dimension.dimension_code === topinfluencersCode
              ? `${baseUrl}/nps_breakdown_by_top_influencers`
              : `${baseUrl}/nps_breakdown_by_dimension`;
          const subPromices = [
            post(url, {
              ...filters,
              dimensionValues,
              filter: dimension.dimension_code,
            }),
          ];
          if (
            toArray(filters.compareSurveyIdList).length &&
            toArray(filters.compareHierarchyList).length
          ) {
            const compareFilters = {
              ...filters,
              surveyIdList: filters.compareSurveyIdList,
              hierarchyList: filters.compareHierarchyList,
              groupIdList: filters.compareGroupIdList,
            };
            subPromices.push(
              post(url, {
                ...compareFilters,
                filter: dimension.dimension_code,
              }),
            );
          }
          Promise.all(subPromices).then((res) => {
            const list = toArray(res[0]);
            const dimValues = new Set();
            list.forEach((item) => {
              dimValues.add(item.dimensionValue);
            });
            if (dimValues.size > 1) {
              const priorList = toArray(res[1]);
              if (priorList.length) {
                list.forEach((item) => {
                  const priorItem = priorList.find(
                    (i) =>
                      i.dimensionValue === item.dimensionValue &&
                      i.question_id === item.question_id,
                  );
                  if (priorItem) {
                    item.delta = item.value - priorItem.value;
                  }
                });
              }
              list?.forEach((itm) => {
                itm.dimensionValue = getDimensionValueName(
                  dimension.display_name,
                  itm.dimensionValue,
                );
              });
              npsBreakdownByDimension.push({
                name: dimension.display_name,
                code: dimension.dimension_code,
                dimensionId: dimension.id,
                values: list.sort((a, b) => a.value - b.value),
              });
            }
            resolve();
          });
        });
        promices.push(promise);
      }
    });
  await Promise.all(promices);
  return { npsBreakdownByDimension, npsBreakdown };
}

export default getNPSByDimension;
