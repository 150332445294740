/* eslint-disable max-lines */
import {
  faCheckSquare,
  faCommentDots,
  faHashtag,
  faListUl,
  faThumbsUp,
  faUserTie,
} from '@fortawesome/free-solid-svg-icons';

export const API = {
  Version1: 1,
  Version2: 2,
};

export const LOCALES = [
  { language: 'Bahasa Melayu (Malay)', locale: 'ms' },
  { language: 'မြန်မာဘာသာ (Burmese)', locale: 'my' },
  { language: '简体中文 (Chinese Simplified)', locale: 'zh-cn' },
  { language: '繁體中文 (Chinese Traditional)', locale: 'zh-tw' },
  { language: 'Česky (Czech)', locale: 'cs' },
  { language: 'Dansk (Danish)', locale: 'da' },
  { language: 'Nederlands (Dutch)', locale: 'nl' },
  { language: 'English', locale: 'en' },
  { language: 'Français (French)', locale: 'fr' },
  { language: 'Français (Canadian French)', locale: 'fr-ca' },
  { language: 'Deutsch (German)', locale: 'de' },
  { language: 'Ελληνικά (Greek)', locale: 'el' },
  { language: 'Hmong', locale: 'hmn' },
  { language: 'Bahasa Indonesia (Indonesian)', locale: 'id' },
  { language: 'Italiano (Italian)', locale: 'it' },
  { language: '日本語 (Japanese)', locale: 'ja' },
  { language: 'Karen', locale: 'kar' },
  { language: '한국어 (Korean)', locale: 'ko' },
  { language: 'Polski (Polish)', locale: 'pl' },
  { language: 'Português (Portuguese Brazilian)', locale: 'pt' }, // Portuguese Brazil
  { language: 'Português (Portuguese)', locale: 'pt-pt' }, // Portuguese European
  { language: 'Română (Romanian)', locale: 'ro' },
  { language: 'Русский (Russian)', locale: 'ru' },
  { language: 'Srpski (latinica) (Serbian Latin)', locale: 'sr-latn' },
  { language: 'Español (Spanish)', locale: 'es' },
  { language: 'Español - Latinoamerica (Spanish)', locale: 'es-x' },
  { language: 'Svenska (Swedish)', locale: 'sv' },
  { language: 'Tagalog (Tagalog)', locale: 'tl' },
  { language: 'ภาษาไทย (Thai)', locale: 'th' },
  { language: 'Türkçe (Turkish)', locale: 'tr' },
  { language: 'Tiếng Việt (Vietnamese)', locale: 'vi' },
  { language: 'हिंदी (Hindi)', locale: 'hi' },
  { language: 'አማርኛ (Amharic)', locale: 'am' },
  { language: 'عربي (Arabic)', locale: 'ar' },
  { language: 'Euskara (Basque)', locale: 'eu' },
  { language: 'বাংলা (Bengali)', locale: 'bn' },
  { language: 'Български (Bulgarian)', locale: 'bg' },
  { language: 'Català (Catalan)', locale: 'ca' },
  { language: 'Hrvatski (Croatian)', locale: 'hr' },
  { language: 'Eestlane (Estonian)', locale: 'et' },
  { language: 'Pilipino (Filipino)', locale: 'fil' },
  { language: 'Suomi (Finnish)', locale: 'fi' },
  { language: 'ગુજરાતી (Gujarati)', locale: 'gu' },
  { language: 'עִברִית (Hebrew)', locale: 'iw' },
  { language: 'Magyar (Hungarian)', locale: 'hu' },
  { language: 'Íslenska (Icelandic)', locale: 'is' },
  { language: 'ಕನ್ನಡಕ (Kannada)', locale: 'kn' },
  { language: 'Latvietis (Latvian)', locale: 'lv' },
  { language: 'Lietuvis (Lithuanian)', locale: 'lt' },
  { language: 'മലയാളം (Malayalam)', locale: 'ml' },
  { language: 'मराठी (Marathi)', locale: 'mr' },
  { language: 'Norsk (Norwegian)', locale: 'no' },
  { language: 'Slovák (Slovak)', locale: 'sk' },
  { language: 'Slovenščina (Slovenian)', locale: 'sl' },
  { language: 'Kiswahili (Swahili)', locale: 'sw' },
  { language: 'தமிழ் (Tamil)', locale: 'ta' },
  { language: 'తెలుగు (Telugu)', locale: 'te' },
  { language: 'اردو (Urdu)', locale: 'ur' },
  { language: 'Українська (Ukrainian)', locale: 'uk' },
  { language: 'Cymraeg (Welsh)', locale: 'cy' },
  { language: 'Қазақ (Kazakh)', locale: 'kk' },
  { language: 'Punjabi (Devnagari)', locale: 'pan' },
  { language: 'English (United Kingdom)', locale: 'en-gb' },
  { language: 'ພາສາລາວ (Lao)', locale: 'lo' },
];

/* Reports */

export const REPORT_VIEW_TYPE = {
  Regular: 'regular',
  Type360: '360',
  Vcp: 'vcp',
};

export const TOP_STATEMENTS_KEYTERMS = [
  'critical hire',
  'concerns for current plan',
  'company priorities',
  'company recommendations',
];
export const INITIAL_SLIDES_SETTINGS = {
  Benchmarks: {
    type: 'benchmark_all',
    valueType: 'minQ1',
  },
  BusinessOverview: {
    hideBenchmarks: false,
    hideNPSBenchmarks: false,
    hideTable: false,
  },
  ComparativeReview: {
    col: {
      field: 'score',
      list: [],
      method: 'asc',
    },
    row: {
      field: 'spread',
      list: [],
      method: 'desc',
    },
  },
  ONA: {
    dimensionCode: 'department',
    tableDimensions: ['title', 'department', 'tenure'],
  },
  answerDistributionDisplayMode: 'count',
  chapterBreaker: true,
  enableAISummary: false,
  questionsVisibility: [],
  showCategoriesSlides: false,
  showDeltaInXlsx: false,
  showOnaSlidesInReport: true,
};

export const INITIAL_360_SLIDES_SETTINGS = {
  360: {
    answerDistributionDisplayMode: 'count',
    chapterBreaker: true,
    showBenchmark: true,
    showManagerFeedback: true,
    showManagerOverallRating: true,
    showParticipantsList: false,
    statementAgrAppendix: false,
    statementAgrForRole: false,
    withAboutPage: true,
  },
  ComparativeReview: {
    col: {
      field: 'score',
      list: [],
      method: 'asc',
    },
    row: {
      field: 'spread',
      list: [],
      method: 'desc',
    },
  },
  ONA: {
    dimensionCode: 'department',
    tableDimensions: ['title', 'department', 'tenure'],
  },
  questionsVisibility: [],
};

export const INITIAL_VCP_SLIDES_LIST = {
  benchmarks: {
    priority: 2,
    visible: true,
  },
  businessContext: {
    priority: 2,
    visible: false,
  },
  businessOverview: {
    priority: 2,
    visible: true,
  },
  fixedAndNumerical: {
    priority: 2,
    visible: true,
  },
  fixedBreakdown: {
    priority: 2,
    visible: true,
  },
  fixedByDimension: {
    priority: 2,
    visible: true,
  },
  fixedByDimensionKeys: {
    priority: 2,
    visible: true,
  },
  keyDrivers: {
    priority: 2,
    visible: true,
  },
  keyFindings: {
    priority: 2,
    visible: true,
  },
  keyRecommendations: {
    priority: 2,
    visible: true,
  },
  keyRoles: {
    priority: 2,
    visible: true,
  },
  levelValueRisks: {
    priority: 2,
    visible: true,
  },
  listSelectsByDimension: {
    priority: 2,
    visible: true,
  },
  misalignmentKeyRolesAndCapabilities: {
    priority: 2,
    visible: true,
  },
  multiselect: {
    priority: 2,
    visible: true,
  },
  nps: {
    priority: 2,
    visible: true,
  },
  onaCollaborationAndOutreachBetweenGroups: {
    priority: 2,
    visible: true,
  },
  onaCollaborationBetweenTeamsByDimension: {
    priority: 2,
    visible: true,
  },
  onaConnectionsBetweenInfluencersInEachGroupByDimension: {
    priority: 2,
    visible: true,
  },
  onaPotentialInfluencersIdentified: {
    priority: 2,
    visible: true,
  },
  onaTopInfluencers: {
    priority: 2,
    visible: true,
  },
  organizationalCapabilities: {
    priority: 2,
    visible: true,
  },
  organizationalMaturity: {
    priority: 2,
    visible: true,
  },
  overallGraphs: {
    priority: 2,
    visible: true,
  },
  participation: {
    priority: 2,
    visible: true,
  },
  statementsByDimension: {
    priority: 2,
    visible: true,
  },
  strengths: {
    priority: 2,
    visible: true,
  },
  summaryKOC: {
    priority: 2,
    visible: true,
  },
  topCommentRecommendations: {
    priority: 2,
    visible: false,
  },
  topCommentsByValueDrivers: {
    priority: 2,
    visible: true,
  },
  topKeyRolesToDeliver: {
    priority: 2,
    visible: true,
  },
  topStatements: {
    priority: 2,
    visible: true,
  },
  vcpBridge: {
    priority: 2,
    visible: true,
  },
  vcpBusinessOverview: {
    priority: 2,
    visible: true,
  },
  vcpComments: {
    priority: 2,
    visible: true,
  },
  vcpOverallScores: {
    priority: 2,
    visible: true,
  },
};

export type SlideSettingsType = typeof INITIAL_SLIDES_SETTINGS | typeof INITIAL_360_SLIDES_SETTINGS;

export const INITIAL_SLIDES_LIST = {
  actionPlan: {
    priority: 2,
    visible: true,
  },
  benchmarks: {
    priority: 2,
    visible: true,
  },
  businessOverview: {
    priority: 2,
    visible: true,
  },
  fixedAndNumerical: {
    priority: 2,
    visible: true,
  },
  fixedBreakdown: {
    priority: 2,
    visible: true,
  },
  fixedByDimension: {
    priority: 2,
    visible: true,
  },
  fixedByDimensionKeys: {
    priority: 2,
    visible: true,
  },
  keyFindings: {
    priority: 2,
    visible: true,
  },
  listSelectsByDimension: {
    priority: 2,
    visible: true,
  },
  multiselect: {
    priority: 2,
    visible: true,
  },
  nps: {
    priority: 2,
    visible: true,
  },
  onaCollaborationAndOutreachBetweenGroups: {
    priority: 2,
    visible: true,
  },
  onaCollaborationBetweenTeamsByDimension: {
    priority: 2,
    visible: true,
  },
  onaConnectionsBetweenInfluencersInEachGroupByDimension: {
    priority: 2,
    visible: true,
  },
  onaPotentialInfluencersIdentified: {
    priority: 2,
    visible: true,
  },
  onaTopInfluencers: {
    priority: 2,
    visible: true,
  },
  overallGraphs: {
    priority: 2,
    visible: true,
  },
  overview: {
    priority: 1,
    visible: true,
  },
  participation: {
    priority: 2,
    visible: true,
  },
  statementsByDimension: {
    priority: 2,
    visible: true,
  },
  strengths: {
    priority: 2,
    visible: true,
  },
  topStatements: {
    priority: 2,
    visible: true,
  },
};

// temp - remove once PPT fetches data from the API
export const INITIAL_SLIDE_LIST_BULK = {
  ...INITIAL_SLIDES_LIST,
  keyFindingsBulk: {
    visible: true,
  },
};

export const INITIAL_360_SLIDES_LIST = {
  coreCompetencies: {
    priority: 2,
    visible: true,
  },
  fixedAndNumerical: {
    priority: 2,
    visible: true,
  },
  fixedBreakdown: {
    priority: 2,
    visible: true,
  },
  fixedByDimension: {
    priority: 2,
    visible: true,
  },
  fixedByDimensionKeys: {
    priority: 2,
    visible: true,
  },
  listSelectsByDimension: {
    priority: 2,
    visible: true,
  },
  multiselect: {
    priority: 2,
    visible: true,
  },
  special360: {
    priority: 2,
    visible: true,
  },
  statementsByDimension: {
    priority: 2,
    visible: true,
  },
  strengths: {
    priority: 2,
    visible: true,
  },
  supportData: {
    priority: 2,
    visible: true,
  },
  supportDataByDimmension: {
    priority: 2,
    visible: true,
  },
  topStatements: {
    priority: 2,
    visible: true,
  },
};

export const CONSOLIDATION_BUCKETS = {
  q1: 'Lowest 25%',
  q2: 'Next Lowest 25%',
  q3: '2nd Highest 25%',
  q4: 'Highest 25%',
};
/* end reports */

/* Questions */
export const QUESTIONS_TYPES = {
  FIXED: 1,
  LIST: 7,
  MULTISELECT: 11,
  NPS: 9,
  NUMERICAL: 4,
  SECTION: 3,
  SPECIAL_360: 10,
  STATEMENT: 2,
};

export const QUESTIONS_METADATA = {
  SELF_ASSESSMENT_RATE: 'self_assessment_rate',
  SELF_ASSESSMENT_TEXT: 'self_assessment_text',
};

export const QUESTIONS_TYPES_LIST = [
  {
    exportName: 'Fixed Answer',
    icon: faCheckSquare,
    id: QUESTIONS_TYPES.FIXED,
    name: 'Fixed Answer',
    typecode: 'fixedanswer',
  },
  {
    exportName: 'Statement',
    icon: faCommentDots,
    id: QUESTIONS_TYPES.STATEMENT,
    name: 'Free-Form Response',
    typecode: 'statement',
  },
  {
    exportName: 'Section',
    hidden: true,
    id: QUESTIONS_TYPES.SECTION,
    name: 'Section',
    typecode: 'section',
  },
  {
    exportName: 'Numerical',
    icon: faHashtag,
    id: QUESTIONS_TYPES.NUMERICAL,
    name: 'Numerical',
    typecode: 'numerical',
  },
  {
    exportName: 'List Select',
    icon: faListUl,
    id: QUESTIONS_TYPES.LIST,
    name: 'List Select',
    typecode: 'listselect',
  },
  {
    exportName: 'Nps',
    icon: faThumbsUp,
    id: QUESTIONS_TYPES.NPS,
    name: 'NPS',
    typecode: 'nps',
  },
  {
    exportName: '360 special',
    icon: faUserTie,
    id: QUESTIONS_TYPES.SPECIAL_360,
    name: '360 special',
    typecode: '360_special',
  },

  {
    exportName: 'multiselect',
    icon: faUserTie,
    id: QUESTIONS_TYPES.MULTISELECT,
    name: 'Multiselect',
    typecode: 'multiselect',
  },
];

export const ONA_KEY_TERMS = [
  'Provides strategic direction',
  'Helps with complex problems',
  'Advice for decisions',
  'Discuss new ideas',
  'Provides support in difficulties',
  'Inspires people',
  'Personal support',
  'Career advice',
  'Unreachable enabler',
];

/* end questions */

/* Filters */
// TODO: remove
export const FREQUENCIES = [
  {
    id: 'weekly',
    name: 'Weekly',
  },
  {
    id: 'biweekly',
    name: 'Biweekly',
  },
  {
    id: 'monthly',
    name: 'Monthly',
  },
  {
    id: 'quarterly',
    name: 'Quarterly',
  },
  {
    id: 'semi_annual',
    name: 'Semi Annual',
  },
  {
    id: 'annual',
    name: 'Annual',
  },
];
/* end filters */

/* Storage keys */
export const USER_STORAGE = 'entromyUser';
export const TOKEN_STORAGE = 'token';
export const FILTERS_STORAGE = 'filters';
export const SSO_STORAGE = 'sso';
export const AUTH_REJECTED_STORAGE = 'auth_rejected';
export const POSTPONE_DATE_2FA = '2faPostponeUntil';

/* Errors */
export const NO_REPORT_ACCESS =
  'You have not been assigned view access to any results. Please contact your administrator.';
export const NO_REPORT_DATA = "You don't have enough data for the selected filters";
export const REPORT_FAILED = (
  <div>
    Something went wrong. Please try again. If the issue persists, please contact support through{' '}
    <a href="mailto:help@entromy.com">help@entromy.com</a>.
  </div>
);

/* Warnings */
export const UNSAVED_DATA = 'Are you sure? You will lose unsaved data.';

/* Date Format */
export const DEFAULT_UI_DATE_FORMAT = 'MM/DD/YYYY';
export const DEFAULT_API_FORMAT = 'YYYY-MM-DD';
export const DEFAULT_MONTH_SHORT_YEAR_FORMAT = 'MMM YY';
export const DEFAULT_UI_DATE_TIME_FORMAT = 'MM/DD/YYYY, HH:mm';

/* Dimensions */
export const DIMENSION_FIELD_TYPES = [
  {
    text: 'Text',
    value: 'string',
  },
  {
    text: 'Password',
    value: 'password',
  },
  {
    text: 'Email',
    value: 'email',
  },
  {
    text: 'Phone',
    value: 'phone',
  },
  {
    text: 'Number',
    value: 'number',
  },
  {
    text: 'Radio Button',
    value: 'radio_button',
  },
  {
    text: 'Dropdown',
    value: 'dropdown',
  },
  {
    text: 'Date',
    value: 'date',
  },
];

export const DIMENSION_STATUSES = [
  {
    text: 'Confidential',
    value: 1,
  },
  {
    text: 'Managed',
    value: 0,
  },
];

export const DIMENSION_STATUSES_CODES = {
  CONFIDENTIAL: 1,
  MANAGED: 0,
};

export const DIMENSION_SCOPE_TYPES = [
  {
    text: 'System',
    value: 'system',
  },
  {
    text: 'User Defined',
    value: 'user_defined',
  },
];

export const VISIBILITY_TYPES = [
  {
    text: 'Visible',
    value: true,
  },
  {
    text: 'Hidden',
    value: false,
  },
];

export const YES_NO_TYPES = [
  {
    text: 'Yes',
    value: true,
  },
  {
    text: 'No',
    value: false,
  },
];
/* end dimensions */

/* Statements */

export const STATEMENT_VISIBILITIES = [
  {
    id: 1,
    name: 'Visible',
  },
  {
    id: 2,
    name: 'For review',
  },
  {
    id: 0,
    name: 'Excluded',
  },
];

export const SURVEY_TYPE = {
  CHILD_360: '360',
  DEI: 'dei',
  ESG: 'esg',
  PARENT_360: 'comm',
  REGULAR: 'regular',
  VCP: 'vcp',
};

export const PORTFOLIO_VIEW_TYPE = {
  Regular: 'regular',
  Type360: '360',
};

export const QUESTION_THEMES = {
  DO_BETTER: 1,
  DO_WELL: 2,
  RECOMMEND: 3,
};
/* end statements */

/* users */
export const STATUSES = [
  { label: 'Active', value: 1 },
  { label: 'Inactive', value: 2 },
];
/* end users */

/* Other */
export const SPECIALS_LIST = {
  ENTROMY_LOGIN: 'ENTROMY_LOGIN',
  ENTROMY_URL: 'ENTROMY_URL',
  ONDEMAND_LINK: 'ONDEMAND_LINK',
  PRIVACY_LINK: 'PRIVACY_LINK',
  SURVEY_END_DATE: 'SURVEY_END_DATE',
  SURVEY_LINK: 'SURVEY_LINK',
  USER_NAME: 'USER_NAME',
};

export const EMAIL_TYPES = {
  INITIAL: 2,
  INITIAL_MANAGER: 3,
  REMINDER: 1,
  REMINDER_MANAGER: 4,
};

export const EMAIL_TYPES_NAME = {
  ALL_MESSAGES: 'all',
  DISCLAIMER: 'terms',
  INITIAL: 'initialEmail',
  KIOSK: 'kioskMessage',
  REMINDER: 'reminderEmail',
  SMS_TEXT: 'takeSurveySmsText',
  THANK_YOU: 'thankYouText',
  WELCOME: 'welcomeText',
};

export const DEI_STATUSES = [
  { label: 'Not started', value: 'not_started' },
  { label: 'In Progress', value: 'in_progress' },
  { label: 'Submitted', value: 'submitted' },
];

/* end other */

/* DEI */
export const DEI_TEMPLATES = [
  {
    code: 'fr-dei',
    name: 'Fraizer',
  },
  {
    code: 'fr-dei-lite',
    name: 'Fraizer Pulse',
  },
  {
    code: 'fp-dei',
    name: 'Francisko Partners',
  },
  {
    code: 'demo-dei',
    name: 'Demo',
  },
];

export const ESG_TEMPLATES = [
  {
    code: 'fr-esg',
    name: 'Fraizer',
  },
  {
    code: 'fr-esg-lite',
    name: 'Fraizer Pulse',
  },
  {
    code: 'demo-esg',
    name: 'Demo',
  },
];

/* end DEI */

export const CONTACT_METHODS = [
  { label: 'Email', value: 'email' },
  { label: 'Alt Email', value: 'altemail' },
  { label: 'Text', value: 'text' },
  { label: 'All', value: 'all' },
  { label: 'None', value: 'none' },
];

export const PORTFOLIO_PERMISSION_VALUES = {
  EDIT: 2,
  OWNER: 3,
  READ: 1,
};

export const PORTFOLIO_PERMISSIONS = [
  { label: 'Read Only', value: 1 },
  { label: 'Edit', value: 2 },
];

export const RULES_360 = {
  rule1: {
    devArea: {
      action: 'Rate it as a Dev area',
      field: '% of responders that rates competency as Dev area',
    },
    strength: {
      action: 'Rate it as a strength',
      field: '% of responders that rates competency as top strength',
    },
  },
  rule2: {
    opportunities: {
      action: 'Rate it as Growth opportunities',
      field: '% of responders that "somewhat agree" & "somewhat disagree" to the competency',
    },
  },
  rule3: {
    opportunities: {
      action: 'Rate it as Growth opportunities',
      field: '% of responders that rates competency as top strength & Dev area',
    },
  },
  rule4: {
    devArea: {
      action: 'Rate it as Dev area',
      field: '% of responders that rates competency as Dev area',
    },
    strength: {
      action: 'Rate it as strength',
      field: '% of responders that rates competency as top strength',
    },
  },
  rule5: {
    devArea: {
      action: 'Rate it as Dev area',
      field: '% of responders that "disagree" & "strongly disagree" to the competency',
    },
    strength: {
      action: 'Rate it as strength',
      field: '% of responders that "agree" & "strongly agree" to the competency',
    },
  },
};

/* VCP */

export const ORG_DETAILS = {
  CONNTEXT: {
    code: 'context',
    label: 'Additional context',
    type: 'textarea',
  },
  EMPLOYEES_COUNT: {
    code: 'employessCount',
    label: '# of employees',
    min: 0,
    type: 'number',
  },
  GEOGRAPHIES_COUNT: {
    code: 'geographiesCount',
    label: '# of geographies',
    min: 0,
    type: 'number',
  },
  GROWTH_RATE: {
    code: 'growthRate',
    formatter: (val: any) => `${val}%`,
    label: 'Avg % growth rate for last 3 years',
    parser: (val: string) => val.replace('%', ''),
    type: 'number',
  },
  PE_OWNED_YEARS_COUNT: {
    code: 'PEOwnedYearsCount',
    label: '# of years PE owned',
    min: 0,
    type: 'number',
  },
  UNITS_COUNT: {
    code: 'unitsCount',
    label: '# of business units',
    min: 0,
    type: 'number',
  },
};

export const QUESTIONS_ANSWERS = [
  { text: 'Strongly Disagree', value: 6 },
  { text: 'Disagree', value: 5 },
  { text: 'Somewhat Disagree', value: 4 },
  { text: 'Somewhat Agree', value: 3 },
  { text: 'Agree', value: 2 },
  { text: 'Strongly Agree', value: 1 },
];

export const NSP_QUESTION_ANSWERS = [
  { text: '0-6', value: '0,6' },
  { text: '7-8', value: '7,8' },
  { text: '9-10', value: '9,10' },
];
/* end of VCP */

export const DIMENSION_VALUES_CODES = {
  SHOW_ANSWERS_MANAGERS_ONLY_360: '360_show_answers_managers_only',
  TYPE_360: '360_type',
};

export const MANAGER_REVIEW_STATUS = {
  Completed: 2,
  InProgress: 1,
  NotStarted: 0,
  Overdue: 3,
};

export const RATE_ITEMS = [
  {
    text: 'Does Not Meet Expectations',
    value: 1,
  },
  {
    text: 'Inconsistently Meets Expectations',
    value: 2,
  },
  {
    text: 'Meets Expectations',
    value: 3,
  },
  {
    text: 'Periodically Exceeds Expectations',
    value: 4,
  },
  {
    text: 'Consistently Exceeds Expectations',
    value: 5,
  },
];

export const RATE_MAP = RATE_ITEMS.reduce(
  (acc, { value, text }) => ({ ...acc, [value]: text }),
  {},
);

export const ORG_DIMENSION_TYPE = {
  country: 'headquartersCountries',
  portfolio: 'portfolios',
  state: 'headquartersStates',
};

export const EMPTY_DIMENSION_VALUE_NAME = 'Uncategorized';
